import React, {useEffect} from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory
} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import IssueListPage from "./pages/Issues/IssueListPage";
import InternalReportsListPage from "./pages/InternalReports/InternalReportsListPage";
import InternalReportDetails from "./pages/InternalReports/InternalReportDetails";
import SSMInternalReport from "./pages/Issues/SSMInternalReport";
import CreateAdvice from "./pages/Advice/CreateAdvice";
import AdviceListPage from "./pages/Advice/AdviceListPage";
import CreatePost from "./pages/Posts/CreatePost";
import PostListPage from "./pages/Posts/PostListPage";
import AddAdministrator from "./pages/Admins/AddAdministrator";
import AdministratorListPage from "./pages/Admins/AdministratorListPage";
import IssueDetails from "./pages/Issues/IssueDetails";
import Profile from "./pages/Profile";
import UserListPage from "./pages/Users/UserListPage";
import StatisticsByAge from './pages/Statistics/StatisticsByAge';
import StatisticsByCategory from './pages/Statistics/StatisticsByCategory';
import StatisticsByCity from './pages/Statistics/StatisticsByCity';
import StatisticsByEmployment from './pages/Statistics/StatisticsByEmployment';
import StatisticsByGender from './pages/Statistics/StatisticsByGender';
import StatisticsByIndustry from './pages/Statistics/StatisticsByIndustry';
import StatisticsByAnonimity from './pages/Statistics/StatisticsByAnonimity';
import StatisticsByMembership from './pages/Statistics/StatisticsByMembership';
import StatisticsByReports from './pages/Statistics/StatisticsByReports';
import StatisticsByReportStatus from './pages/Statistics/StatisticsByReportStatus';
import StatisticsByReportType from './pages/Statistics/StatisticsByReportType';
import NotFound from "./pages/NotFound";
import ResetPassword from "./pages/ResetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {

  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/issues" component={IssueListPage} />
        <Route path="/internalReports" component={InternalReportsListPage} />
        <Route path="/internalReportDetails/:id" component={InternalReportDetails} />
        <Route path="/SSMInternalReport/:id" component={SSMInternalReport} />
        <Route path="/issueDetails/:id" component={IssueDetails} />
        <Route path="/createPost" component={CreatePost} />
        <Route path="/editPost/:id" component={CreatePost} />
        <Route path="/posts" component={PostListPage} />
        <Route path="/createAdvice" component={CreateAdvice} />
        <Route path="/editAdvice/:id" component={CreateAdvice} />
        <Route path="/advice" component={AdviceListPage} />
        <Route path="/addAdministrator" component={AddAdministrator} />
        <Route path="/editAdministrator/:id" component={AddAdministrator} />
        <Route path="/administrators" component={AdministratorListPage} />
        <Route path="/profile" component={Profile} />
        <Route path="/users" component={UserListPage} />
        <Route path="/statsByAge" component={StatisticsByAge} />
        <Route path="/statsByCategory" component={StatisticsByCategory} />
        <Route path="/statsByCity" component={StatisticsByCity} />
        <Route path="/statsByGender" component={StatisticsByGender} />
        <Route path="/statsByEmployment" component={StatisticsByEmployment} />
        <Route path="/statsByIndustry" component={StatisticsByIndustry} />
        <Route path="/statsByMembership" component={StatisticsByMembership} />
        <Route path="/statsByReports" component={StatisticsByReports} />
        <Route path="/statsByAnonimity" component={StatisticsByAnonimity} />
        <Route path="/statsByReportStatus" component={StatisticsByReportStatus} />
        <Route path="/statsByReportType" component={StatisticsByReportType} />
        <Route path="/resetPassword" component={ResetPassword} />
        <Route path="/privacyPolicy" component={PrivacyPolicy} />
        <Route path="/*" component={NotFound} />
        <Route path="/" component={Login} />
      </Switch>
    </Router>
  );
}
export default App;
