import React, { useState, useEffect } from "react";
import logo from "../img/logo.svg";
import * as yup from "yup";

import { useParams, useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { login } from "../actions/user";

import Layout from "../../src/Layout";
import { getProfile } from "../actions/user";

const LoginShema = yup.object().shape({
  identifier: yup.string().required("Ова поле е задолжително"),
  password: yup.string().required("Ова поле е задолжително"),
});

const startValues = {
  identifier: "",
  password: "",
};

const Login = ({ login, getProfile }) => {
  const [initialValues, setInitialValues] = useState(startValues);

  let { id } = useParams();
  const history = useHistory();

  useEffect(async () => {
    let response = null;
    try {
      response = await getProfile();
      history.push("/dashboard");
    } catch (error) {
      console.log(error);
    }
  }, []);

  const formSubmit = async (values) => {
    try {
      let user = null;
      user = await login({
        ...values,
      });
      history.push("/dashboard");
    } catch (e) {
      console.log(e);
      alert(
        "Погрешна е-маил адреса или лозинка. Ве молиме обидете се повторно."
      );
    }
  };

  return (
    <div className="fixed left-0 z-50 top-0 w-full h-full overflow-auto">
      <div className="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 m-0 mx-auto max-h-screen">
        <div className="flex flex-col w-full max-w-md px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white mb-10 text-center">
            Најава админ панел
            <img src={logo} className="mt-10 w-64 text-center block mx-auto" alt="SSSS logo" />
          </div>
          <div className="mt-8">
            <Formik
              initialValues={initialValues}
              validationSchema={LoginShema}
              validateOnChange={false}
              enableReinitialize={true}
              onSubmit={formSubmit}
            >
              <Form>
                <div className="flex flex-col mb-2">
                  <div className="flex relative ">
                    <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                      <svg
                        width="15"
                        height="15"
                        fill="currentColor"
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                      </svg>
                    </span>
                    <Field
                      name="identifier"
                      placeholder="Унеси е-поштом"
                      type="text"
                      className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                    ></Field>
                    <div className="text-red text-center mb-10">
                      <ErrorMessage name="identifier" />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mb-6">
                  <div className="flex relative ">
                    <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                      <svg
                        width="15"
                        height="15"
                        fill="currentColor"
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z"></path>
                      </svg>
                    </span>
                    <Field
                      name="password"
                      placeholder="Унеси лозинка"
                      type="password"
                      className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                    ></Field>
                    <div className="text-red text-center mb-10">
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                </div>
                {/* <div className="flex items-center mb-6 -mt-4">
                  <div className="flex ml-auto">
                    <a
                      href="#"
                      className="inline-flex text-xs font-thin text-gray-500 sm:text-sm dark:text-gray-100 hover:text-gray-700 dark:hover:text-white"
                    >
                      Заборавена лозинка?
                    </a>
                  </div>
                </div> */}
                <div className="flex w-full">
                  <button
                    type="submit"
                    className="py-2 px-4 bg-blue text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                  >
                    Најави се
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getProfile,
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
